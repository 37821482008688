.typewriter-container {
  position: absolute;
  top: 20vh; 
  width: 100%;
  text-align: center;
  z-index: 2; 
}

.typewriter {
  font-size: 2rem;
  font-weight: bold;
  color: #F1B4BB; 
  margin-top: 10px;
  min-height: 24px; 
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .typewriter {
    font-size: 1.5rem; 
  }
}

@media screen and (max-width: 480px) {
  .typewriter {
    font-size: 1.3rem;
  }
}

.hero-section {
  background-image: url('../../../public/AmandaPushuplook.jpg'); 
  background-size: cover;
  background-position: top-center;
  background-repeat: no-repeat;
  height: 100vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px 20px;
  position: relative;
  z-index: 3;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 70%, 0 100%);
}

.hero-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 100%);
  z-index: 4;
}

.container {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  position: bottom;
  z-index: 2; 
  background: rgba(255, 255, 255, 0.6); 
  padding: 20px;
  border-radius: 10px;
}

.title {
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 1.2em;
  margin-bottom: 20px;
}