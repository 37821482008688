.about-container {
  background: linear-gradient(to right, #fdf0f0, #f1b4bb);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 40px;
  max-width: 1000px;
  margin: 0 auto;
}

.image-badge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-image {
  width: 50%;
  max-width: 400px;
  height: auto;
  border-radius: 10px;
}

.badges-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
  max-width: 400px;
}

.badge {
  background: linear-gradient(to right, rgba(253, 240, 240, 0.8), rgba(241, 180, 187, 0.8)); 
  color: #132043 !important;
  font-size: 12px;
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  border: 1px solid rgba(241, 180, 187, 0.9); 
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.badge:hover {
  transform: scale(1.14); 
}

.about-content {
  flex: 1;
  text-align: left;
  max-width: 500px;
}

@keyframes flash {
  0% { color: #132043; }
  50% { color: #F1B4BB; }
  100% { color: #132043; }
}

.amanda {
  animation: flash 2s infinite;
}

.jane {
  animation: flash 2s infinite;
  animation-delay: -1s;
}

.consult-link {
  color: #132043;
  font-weight: bold;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .about-container {
      flex-direction: column;
      text-align: center;
  }
  
  .about-image {
      max-width: 300px;
  }

  .badges-container {
      justify-content: center;
  }

  .badge {
      font-size: 10px;
      padding: 4px 10px;
  }

  .about-content {
      text-align: center;
  }
}
