.testimonial-container {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}

.swiper {
  width: 100%;
  height: auto !important;
}

.testimonial-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left; 
  padding: 20px;
  gap: 20px; 
}

.testimonial-image {
  width: 300px; 
  height: 300px;
  border-radius: 5%; 
  object-fit: cover;
  flex-shrink: 0; 
}

.testimonial-text-container {
  flex: 1; 
  max-width: 500px;
}

.testimonial-text {
  font-size: 1.2rem;
  font-style: italic;
  word-wrap: break-word;
  white-space: normal;
  overflow: visible;
}

.testimonial-name {
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .testimonial-content {
    flex-direction: column; 
    text-align: center; 
  }

  .testimonial-image {
    width: 200px;
    height: 200px;
    margin-bottom: 10px; 
  }

  .testimonial-text-container {
    max-width: 100%;
  }

  .testimonial-text {
    font-size: 1rem;
  }

  .testimonial-name {
    font-size: 1rem;
  }
}
