.nav-wrapper {
  position: fixed;
  background: none; 
  width: 100%;
  z-index: 2;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  height: auto; 
  box-shadow: none !important;
  border-bottom: none;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.logo-container {
  padding-top: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logo-container img {
  width: 300px; 
  height: auto; 
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .logo-container img {
    width: 225px; 
  }
}

@media screen and (max-width: 480px) {
  .logo-container img {
    width: 200px; 
  }
}